<template>
  <div class="church-presentation-template add-template-conversation  add-conversation">
    <page-body class>
      <section class="section-1 bg-block mb-4">
        <h1 class="sm">{{ isEditMode ? translations.tcEditConversation : translations.tcAddConversation }} &mdash; {{ conversation.selectedStateCountry && conversation.selectedStateCountry.text }}</h1>
        <ConversationPageNavigation :i18n="translations.components" :page="currentPage"></ConversationPageNavigation>
        <hr />
        <b-form @submit.prevent class="view-1 g-mb-2">
          <h2 class="h-style-1 mb-3">
            {{ translations.tcDistributionLevel }}
            <span class="i-tooltip" v-b-tooltip.hover :title="translations.vtcDistributionLevel">
              <i-tooltip />
            </span>
          </h2>
          <div class="g-mb-1">
            <switch-item :list="distributionList" :key="'distribution'" v-model="distributionSelected"></switch-item>
          </div>
          <h2 class="h-style-1 mb-3" v-if="isSelectedAreas">{{ translations.tcAreas }}</h2>
          <div class="">
            <areas-tiles
              v-if="isSelectedAreas"
              :items="areasOption"
              :headings="'Default'"
              :checkall="isSelectedAreas"
              v-model="selectedAreas"
              :selectedItems="[...selectedItems, ...chosenAreas]"
              :i18n="translations.components"
            ></areas-tiles>
          </div>
        </b-form>
        <section class="form-buttons">
          <b-button variant="primary" class="mr-3" @click="handleNextClick()" :disabled="disableNextButton">
            {{ translations.tcNext }}
          </b-button>
          <b-button variant="tertiary" @click="handleCancelClick()">{{ translations.tcCancel }}</b-button>
        </section>
      </section>
    </page-body>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import AreaTiles from '@/components/AreasTiles.vue'
import ConversationPageNavigation from './conversation-page-navigation.vue'
import iTooltip from '@/assets/svgs/i-tooltip.vue'
import pageBody from '@/components/page-components/PageBody.vue'
import SwitchItem from '@/components/SwitchItem.vue'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'add-conversation-dist',
  mixins: [translationMixin],
  data() {
    return {
      disableNext: false,
      currentPage: 1,
      distributionList: [
        { text: 'Full State', selected: true, key: 'full' },
        { text: 'Selected Areas', selected: false, key: 'areas' }
      ],
      distributionSelected: null,
      errorMessage: null,
      isEditMode: false,
      isSelectedAreas: false,
      local_conversation: {
        distributionSelected: null,
        org_key: null,
        selectedAreas: []
      },
      selectedAreas: [],
      selectedItems: [],
      translations: {},
      validateError: []
    }
  },
  async created() {
    try {
      this.setLoadingStatus(true)
      await Promise.all([
        this.getViewTranslations(),
        this.getComponentTranslations('areas-tiles', 'conversation-page-navigation')
      ]).then(results => {
        this.stripReadableText(results[2])
        const translatedText = {
          ...results[1],
          common: { ...this.translations.common }
        }
        this.$set(this.translations, 'components', translatedText)
        //this.loadTranslations()
      })
      await this.page_load()
      if (this.conversation.editMode) {
        await this.hydrateConversation()
        this.isEditMode = true
      }
    } catch (e) {
      console.error(e)
    } finally {
      this.setLoadingStatus(false)
    }
  },
  methods: {
    ...mapActions({
      clearConversation: 'conversations/clearConversation',
      getOrganisationHierarchy: 'eventCalendar/getOrganisationHierarchy',
      setLoadingStatus: 'menu/setLoadingStatus',
      updateConversationGeoDist: 'conversations/updateConversationGeoDist'
    }),
    async page_load() {
      try {
        this.setLoadingStatus(true)
        await this.getOrgAreas(this.officerToolbarSelected.country_state || this.demograph.state_key, this.demograph.camp_key, this.demograph.language_key)
        this.distributionList[0].selected = true
        this.distributionSelected = this.distributionList[0]
        this.local_conversation.org_key = this.officerToolbarSelected.country_state || this.demograph.state_key
      } catch (e) {
        console.error(e)
      } finally {
        this.setLoadingStatus(false)
      }
    },
    async hydrateConversation() {
      try {
        this.setLoadingStatus(true)
        this.distributionSelected = this.conversation.distributionSelected
        if (this.distributionSelected.key === 'full')
        {
          this.distributionList[0].selected = true
          this.distributionList[1].selected = false
          this.selectedAreas = []
        }
        else {
          this.distributionList[0].selected = false
          this.distributionList[1].selected = true
          this.selectedAreas = JSON.parse(JSON.stringify(this.conversation.selectedAreas))
        }
        this.local_conversation.org_key = this.conversation.org_key || this.officerToolbarSelected.country_state || this.demograph.state_key
        this.disableNext = false
      } catch (e) {
        console.error(e)
      } finally {
        this.setLoadingStatus(false)
      }
    },
    async getOrgAreas(t_parent_org_key, org_key, lng_key) {
      //this.showLoader()

      await this.getOrganisationHierarchy({ t_parent_org_key: t_parent_org_key })
    },
    async handleNextClick() {
      this.validateGeographicDistribution()

      if (this.validateError.length === 0) {
        if (!this.distributionSelected) {
          this.distributionSelected = this.distributionList[0]
        }
        this.local_conversation.distributionSelected = JSON.parse(JSON.stringify(this.distributionSelected))
        this.local_conversation.selectedAreas = JSON.parse(JSON.stringify(this.selectedAreas))
        await this.updateConversationGeoDist(this.local_conversation)
        this.$router.push({ name: 'conversations-add-event-details' })
      } else {
        this.$swal({
          icon: 'error',
          text: `${this.translations.tcErrorAllFieldsRequired} '${this.validateError.join(', ')}'`,
          confirmButtonText: this.translations.tcOk || 'Ok'
        }).then(result => {
          return
        })
      }
    },
    async handleCancelClick() {
      this.$swal({
        icon: 'question',
        showCancelButton: true,
        cancelButtonColor: '#d33',
        text: this.translations.tcOkayToCancel,
        confirmButtonText: this.translations.tcOk,
        cancelButtonText: this.translations.tcCancel
      }).then(result => {
        if (result.value) {
          this.clearConversation()
          this.$router.push({ name: 'conversations-landing-page' })
        }
      })
    },
    validateGeographicDistribution() {
      if (!!this.distributionSelected && this.distributionSelected.key === 'areas' && this.selectedAreas.length === 0) {
        this.validateError.push(this.translations.tcSelectedAreas || 'Selected Areas')
        this.errorMessage = this.translations.tcSelectAtLeastOneArea || 'Select at least one area.'
      }
    }
  },
  watch: {
    distributionSelected(newval, oldval) {
      if (newval.selected) {
        if (newval.key === 'full') {
          this.isSelectedAreas = false
          this.disableNext = false
        } else {
          this.isSelectedAreas = true
          this.disableNext = this.selectedAreas.length === 0
        }
      }
    },
    selectedAreas(newval, oldval) {
      if (newval.length > 0) {
        this.disableNext = false
      } else {
        this.disableNext = true
      }
    }
  },
  computed: {
    ...mapGetters({
      areasOption: 'eventCalendar/areasOption',
      conversation: 'conversations/conversation',
      demograph: 'user/demograph',
      officerToolbarSelected: 'user/officerToolbarSelected',
      selectedConversationKey: 'user/userSelectedConversationKey'
    }),
    chosenAreas() {
      // Exclude any areas already included in the selected Items list.
      let newAreas = this.selectedAreas.filter(area => {
        let selectedItem = this.selectedItems.filter(selected => {
          return selected.org_key.toUpperCase() === area.org_key.toUpperCase()
        })
        return selectedItem.length === 0
      })
      return newAreas.map(item => {
        return {
          org_key: item.org_key
        }
      })
    },
    disableNextButton() {
      if (this.distributionSelected && this.distributionSelected.key === 'areas' && this.selectedAreas.length > 0) {
        return false
      }
      if (this.distributionSelected && this.distributionSelected.key === 'full') {
        return false
      }
      return true
    },
    selectedStateCountry() {
      if (this.officerToolbarSelected.country_state) {
        return this.officerToolbarSelected.country_state
      }
      return this.demograph.state_key
    },
  },
  components: {
    'areas-tiles': AreaTiles,
    ConversationPageNavigation: ConversationPageNavigation,
    iTooltip: iTooltip,
    pageBody: pageBody,
    'switch-item': SwitchItem
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/settings.scss';
@import '@/styles/bootstrap/b-button.scss';
@import '@/styles/bootstrap/b-button-group.scss';

.page-body {
  padding: 80px 15px;
}

.bg-block {
  padding: 24px;
}
.add-template-conversation {
  h1 {
    margin-bottom: 34px;
  }
  hr {
    margin: 60px 0 35px;
  }
  // @include breakpoint(sm) {
  //   .page-header {
  //     text-align: center;
  //     h1 {
  //       font-size: 42px;
  //     }
  //   }
  // }
  .button-group {
    .btn-primary {
      border: 1px solid #003946;
      text-transform: uppercase !important;
    }
    button {
      border-radius: 0 !important;
    }
  }
  .g-background {
    margin: 90px auto 104px;
    button {
      border-radius: 0 !important;
    }
  }
  // .form-section {
  //   font-size: 22px;
  //   font-weight: bold;
  //   display: block;
  //   line-height: 44px;
  //   letter-spacing: 0.73px;
  //   text-transform: uppercase;
  //   color: #636363;
  //   margin-bottom: 10px;
  // }
  .form-block {
    .form-element {
      margin-bottom: 10px;

      &.form-attachment {
        margin-bottom: 60px;
        margin-top: 45px;
        @include breakpoint(sm) {
          margin-top: 25px;
        }
      }
    }
  }
  .form-control {
    height: auto;
    font-weight: normal;
    text-transform: none;
    line-height: 25px;
    padding: 10px 20px;
    border-color: #636363;
    color: #636363;
  }
  // .custom-checkbox {
  //   padding-left: 34px;
  //   line-height: 24px;
  //   margin-bottom: 10px;
  //   label {
  //     font-size: 16px;
  //     &:before {
  //       top: 0;
  //       left: -34px;
  //       border-radius: 0;
  //       width: 24px;
  //       height: 24px;
  //       border-color: #636363;
  //     }
  //     &:after {
  //       top: 0;
  //       left: -34px;
  //       height: 24px;
  //       width: 24px;
  //     }
  //   }
  //   .custom-control-input:checked ~ .custom-control-label::after {
  //     background-color: #003946;
  //   }
  //   label.custom-control-label {
  //     min-width: 200px;
  //   }
  // }
  @include breakpoint(sm) {
    .form-buttons {
      .btn {
        width: 100%;
        margin-bottom: 15px !important;
        &:last-of-type {
          margin-bottom: 0 !important;
        }
      }
    }
  }
}

.add-conversation {
  .g-mb-1 {
    margin-bottom: 42px;
  }
  .g-mb-2 {
    margin-bottom: 60px;
    @include breakpoint(sm) {
      margin-bottom: 45px;
    }
  }
  .i-tooltip {
    vertical-align: super;
    svg path {
      fill: #000 !important;
    }
  }
  .view-1 {
    @include breakpoint(sm) {
      .btn-group {
        width: 100%;
      }
    }
  }

  .custom-checkbox {
    padding-left: 34px;
    line-height: 24px;
    margin-bottom: 10px;
    label {
      font-size: 16px;
      &:before {
        top: 0;
        left: -34px;
        border-radius: 0;
        width: 24px;
        height: 24px;
        border-color: red !important;
      }
      &:after {
        top: 0;
        left: -34px;
        height: 24px;
        width: 24px;
      }
    }
    .custom-control-input:checked ~ .custom-control-label::after {
      background-color: #003946;
    }
  }
}

// .checkbox-row {
//   display: flex;
//   flex-wrap: wrap;
//   .checkbox-block {
//     min-width: 275px;
//     margin-right: 20px;
//     padding: 24px 20px 35px 30px;
//     // border: 1px solid #e9e9e9;
//     border-radius: 6px;
//     // box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
//     @include breakpoint(sm) {
//       width: 100%;
//       margin-bottom: 15px;
//       margin-right: 0;
//     }
//   }
//   .bg-block-h {
//     display: flex;
//     margin-bottom: 15px;
//     h3 {
//       flex: 1 1 auto;
//       margin: 0;
//       color: $gray-200;
//       font-family: $open-sans;
//       font-size: 16px;
//       font-weight: 700;
//       letter-spacing: initial;
//       line-height: 22px;
//       text-transform: none;
//     }
//     .toggle {
//       height: 25px;
//       width: 25px;
//       flex: 0 0 auto;
//       border: 2px solid $gray-200;
//       border-radius: 100%;
//       display: flex;
//       align-items: center;
//       justify-content: center;
//       &:before,
//       &:after {
//         content: '';
//         display: block;
//         width: 7px;
//         height: 2px;
//         border-radius: 2px;
//         transform: rotate(45deg);
//         transform-origin: bottom;
//         background-color: $gray-200;
//         right: -1px;
//         position: relative;
//       }
//       &:after {
//         left: -1px;
//         right: auto;
//         transform: rotate(-45deg);
//       }
//     }
//   }
//   label {
//     font-size: 16px;
//     line-height: 22px;
//   }
// }
</style>
